import React, { Component } from "react";

import Drawer from "../../containers/Drawer";

//import Menu from "../../containers/Menu";
//import About from "../../containers/About";
import "./style.css";

import { Player } from "@lottiefiles/react-lottie-player";
import smoothscroll from "smoothscroll-polyfill";

//import lottieJson from "../../../assets/animations/FinalAnimationTransparent.json";
import lottieJson from "../../../assets/animations/Whale.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faGripLines } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      menuOpen: false,
      aboutOpen: false,
      mobileArrowOpacity: 1,
      config: null
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateSrollState = this.updateSrollState.bind(this);
    smoothscroll.polyfill();
  }

  componentDidMount() {
    this.getData();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.updateSrollState, true);
  }

  async getData() {
    fetch("assets/configuration.json")
      .then(res => res.json())
      .then(data => {
        this.setState({ config: data });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  updateSrollState() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.setState({ mobileArrowOpacity: 0.0 });
    } else {
      /*
      very ugly stuff to make arrow disappear :D
      looks okey to me / jaha
      */
      var opa = (1 + this.state.height - window.scrollY) / this.state.height;
      if (opa < 0.8) opa = 0.5 * opa;
      this.setState({ mobileArrowOpacity: opa });
    }
  }

  scrollDownBottom() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      window.scroll({
        top: document.body.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  renderFooter() {
    if (this.state.width > 700)
      return (
        <div>
          <div className="open-hours-box">
            <h2 className="sun-thur">{this.state.config.leftTextTop}</h2>
            <h3 style={{ textAlign: "left" }}>
              {this.state.config.leftTextBottom}
            </h3>
          </div>
          <div className="address-box">
            <a
              className="clean-link"
              href={`tel:${this.state.config.phoneNumber}`}
            >
              <h2 className="telephoneNumber">
                {this.state.config.phoneNumber}
              </h2>
            </a>
            <a
              className="clean-link"
              href="https://goo.gl/maps/NpFVPXhoTWij1uV48"
              target="_blank"
              rel="noreferrer"
            >
              <h3>Hamnen i Torekov</h3>
            </a>
          </div>
        </div>
      );
    else {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 5,
            display: "flex",
            width: "100vw",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 1,
            opacity: `${this.state.mobileArrowOpacity}`,
            zIndex: 100
          }}
        >
          <FontAwesomeIcon
            className="bounce"
            size="3x"
            icon={faChevronDown}
            onClick={() => {
              this.scrollDownBottom();
            }}
          />
        </div>
      );
    }
  }

  getLogoSize() {
    if (this.state.width < 700)
      return {
        height: "40vh",
        width: "70vw",
        padding: 0,
        marginTop: "-50%"
      };
    return { height: "70vh", width: "40vw", padding: 0 };
  }
  // 70 . 40
  // nummer +46734007566 lägg in
  getTextStyle() {
    if (this.state.width < 700)
      return {
        display: "flex",
        justifyContent: "center",
        zIndex: 2
      };
    return {
      display: "flex",
      justifyContent: "center",
      zIndex: 2,
      marginTop: "-5%"
    };
  }

  renderMobileInfo() {
    if (this.state.width <= 700) {
      return (
        <div className="mobile-contact-container">
          <div style={{ fontSize: 10, textAlign: "center" }}>
            <h3 style={{ marginBottom: "10%" }} className="drawerText">
              {this.state.config.leftTextTop} <br />{" "}
              {this.state.config.leftTextBottom}
            </h3>
            <a
              href="https://goo.gl/maps/VDqkXvH3YZQ6kTDc7"
              target="_blank"
              className="clean-link"
              rel="noreferrer"
            >
              <h3 className="drawerText" style={{ marginBottom: "10%" }}>
                HAMNEN I TOREKOV
              </h3>
            </a>
            <a
              href={`tel:${this.state.config.phoneNumber}`}
              className="clean-link"
            >
              <h3 className="drawerText" style={{ marginBottom: "10%" }}>
                {this.state.config.phoneNumber}
              </h3>
            </a>
            <a
              href="https://www.instagram.com/mobysshack/"
              target="_blank"
              className="clean-link"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                style={{ marginBottom: "10%" }}
                className="clean-logo"
                size="7x"
                icon={faInstagram}
              />
            </a>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { menuOpen } = this.state;
    if (this.state.config !== null) {
      return (
        <div style={{ display: "block", backgroundColor: "#fffafa" }}>
          <Drawer
            open={menuOpen}
            onClose={() => this.setState({ menuOpen: !menuOpen })}
            menuText={this.state.config.menuText}
          />
          <FontAwesomeIcon
            style={{ position: "absolute", right: 20, top: 10, zIndex: 100 }}
            className="clean-logo"
            onClick={() => this.setState({ menuOpen: true })}
            size="3x"
            icon={faGripLines}
          />
          <div className="main">
            <div className="center-container">
              <Player
                autoplay
                loop
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                src={lottieJson}
                style={this.getLogoSize()}
              ></Player>
            </div>
            {this.renderFooter()}
          </div>
          {this.state.width < 700 ? (
            <hr style={{ marginBottom: "10%" }} className="menu-drawer-hr" />
          ) : null}
          {this.renderMobileInfo()}
        </div>
      );
    }
    return <div></div>;
  }
}
export default Main;
