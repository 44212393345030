import React from "react";
import { SwipeableDrawer } from "@material-ui/core";

import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Drawer = ({ open, onClose, menuText }) => (
  <SwipeableDrawer anchor={"right"} open={open} onClose={() => onClose()}>
    <FontAwesomeIcon
      onClick={() => onClose()}
      style={{
        position: "absolute",
        zIndex: 100,
        top: 10,
        left: 10,
        color: "#b5bdc9"
      }}
      size="2x"
      icon={faChevronRight}
    />
    <div className="menu-drawer-content">
      <div className="text-container">
        <h2 className="drawerText">{menuText}</h2>
        <hr style={{ marginTop: "5%" }} />
        <p style={{ fontSize: "42px", marginTop: "5%" }}>
          <a
            className="clean-link"
            href="assets/Mobys-meny.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Meny
          </a>
        </p>
        <p style={{ fontSize: "42px", marginTop: "5%" }}>
          <a
            className="clean-link"
            href={"https://www.instagram.com/mobysshack/"}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </p>
        <a href="https://anconorder.com/sv/outlets/91d94064-79cd-4eb5-a681-8ec595d14fdd/30344f3b-9fd0-4e23-b709-f7d98354ff6c">
          <img
            alt="AnconOrder"
            src="https://ancon.io/AOresources/buttons/AnconOrder-knapp-tango.png"
            style={{ width: "180px", marginTop: "5%" }}
          />
        </a>
      </div>
    </div>
  </SwipeableDrawer>
);

export default Drawer;
